<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <c-qr-btn
          v-if="educationInfo.documentStatusCd && educationInfo.documentStatusCd !== 'ESC000001'"
          :mobileUrl="'/sop/edu/result/educationResult?eduEducationId='+param.eduEducationId"
        >
            <!-- -교육명 : ' -->
            <!-- -교육일시 : ' -->
          <!-- <template slot="detail">
            <b>{{$label('LBL0002873')}}</b>{{educationInfo.educationName}}
            <br>
            <b>{{$label('LBL0002874')}} </b>{{educationInfo.educationDate}}
          </template> -->
        </c-qr-btn>
        <template v-if="!checkAppr">
          <font class="text-negative" style="font-size:0.9em;font-weight:500;">
            <!-- ※ 연간교육계획에 수립된 교육입니다. 연간교육계획 결재가 승인 된 후 계획완료 기능을 이용하실 수 있습니다. -->
            {{$message('MSG0000864')}}
          </font>
        </template>
        <c-update-btn 
          name="updateBtn"
          :data="param.planUpdateBtnData"
          :btnEditable="btnEditable"
          :flagCondition="flagCondition"
          @back="back"
        />
        <!-- <c-btn  v-show="editable&&!disabled" :showLoading="false" label="교육계획결재" icon="save" color="blue" @btnClicked="planAppr" /> -->
        <!-- 삭제 -->
        <c-btn v-show="editable&&param.eduEducationId&&!disabled&&checkAppr" label="LBLREMOVE" :editable="editable" icon="delete_forever" @btnClicked="remove" />
        <!-- 완료 -->
        <c-btn  v-show="editable&&!disabled&&checkAppr" :showLoading="false" icon="check" label="LBLCOMPLETE"  @btnClicked="planComplete('COMPLETE')" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :educationInfo.sync="educationInfo"
          :educationDate="educationDate"
          :param="param"
          @setData="setData"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>

import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-plan-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        eduEducationId: '',
        eduCourseId: '',
        stepCd: '',
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      educationInfo: {
        eduEducationId: '',
        eduEducationYearPlanId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        estimatedEducationExpenses: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        educationStartTime: '',
        educationEndTime: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',
        checkAppr: '',
        // 위험성평가 결과 항목 추가
        ramRiskAssessmentPlanId: '',
        assessmentName: '',
        ramTechniqueCd: null,
        ramStepCd: '',
        sumEducationTime: '',

        // 자체감사 항목 추가
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
        selfInspectionTitle: '',

        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: 0,
        
        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
      },
      saveCall: {
        saveCallData: '',
      },
      editable: true,
      splitter: 5,
      tab: 'educationPlanInfo',
      
      isComplete: false,
      deleteUrl: '',
      educationDate: '',
      tabItems: [],
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 40
      return numHeight + 'px';
    },
    checkAppr() {
      /**
       * true 인 경우 쓰기가능
       */
      return this.educationInfo.eduEducationYearPlanId ? this.educationInfo.checkAppr === 'ASC9999999' : true;
    },
    disabled() {
      return this.educationInfo.documentStatusCd !== 'ESC000001'
    },
    flagCondition() {
      return this.educationInfo.documentStatusCd === 'ESC000001'
    },
    btnEditable() {
      return this.editable && this.educationInfo.documentStatusCd === 'ESC000005' && Boolean(this.educationInfo.eduEducationId)
    },
  },
  watch: {
    'param.planUpdateBtnData.research'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.getUrl = selectConfig.sop.edu.result.get.url;
      this.deleteUrl = transactionConfig.sop.edu.plan.delete.url;
      this.completeUrl = transactionConfig.sop.edu.annual.complete.url;
      this.redirectUrl = selectConfig.com.mobileHostname.url;

      // code setting
      if (this.param.eduEducationId) {
        this.tabItems = [
          // 기본정보
          { key: uid(), name: 'educationPlanInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./educationPlanInfo.vue'}`) },
          // 교육대상자
          { key: uid(), name: 'educationResultTargetUser', icon: 'how_to_reg', label: 'LBL0002875', component: () => import(`${'./educationResultTargetUser.vue'}`), disabled: false },
          // 교육교재
          { key: uid(), name: 'educationTextBook', icon: 'checklist', label: 'LBL0002809', component: () => import(`${'./educationTextBook.vue'}`), disabled: false },
        ]
      } else {
        this.tabItems = [
          // 기본정보
          { key: uid(), name: 'educationPlanInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./educationPlanInfo.vue'}`) },
          // 교육대상자
          { key: uid(), name: 'educationResultTargetUser', icon: 'how_to_reg', label: 'LBL0002875', component: () => import(`${'./educationResultTargetUser.vue'}`), disabled: true },
          // 교육교재
          { key: uid(), name: 'educationTextBook', icon: 'checklist', label: 'LBL0002809', component: () => import(`${'./educationTextBook.vue'}`), disabled: true },
        ]
      }
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.param.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('emitStep', {
            name: 'setRegInfo',
            param: _result.data,
          })
          let hours = 0;
          let minutes = 0;

          this.$_.forEach(this.educationInfo.eduSubjectList, _item => {
            if (_item.educationTime) {
              let timeArray = _item.educationTime.split(":");
              hours += Number(timeArray[0])
              minutes +=Number(timeArray[1])
            }
          })
          if (this.educationInfo.educationRangeFlag !== 'Y') {
            this.educationDate = this.educationInfo.educationStartDate
          }
          if (minutes >= 100) {
            hours += Number(parseInt(minutes / 60))
            minutes = Number(minutes % 60)
          }
          this.educationInfo.sumEducationTime = hours + '시간 ' + minutes + '분 ';

          this.updateMode = true;
        },);
      } else if (this.param.eduCourseId) {
        this.$http.url = this.$format(selectConfig.sop.edu.course.get.url, this.param.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.forEach(this.educationInfo.eduSubjectList, item => {
            if (!this.educationInfo.deleteEduSubjectList) {
              this.educationInfo.deleteEduSubjectList = []
            }
            if (this.$_.findIndex(this.educationInfo.deleteEduSubjectList, { subjectNo: item.subjectNo }) === -1
              && item.editFlag !== 'C') {
                this.educationInfo.deleteEduSubjectList.push(item)
            }
            this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
          })
          // 빈값들 filter
          this.educationInfo.educationCourseName = _result.data.educationCourseName, // 교육과정명
          this.educationInfo.educationTypeCd = _result.data.educationTypeCd, // 교육구분
          // 교육과정 마스터 데이터 
          this.educationInfo.eduCourseId = _result.data.eduCourseId, // 교육과정 key
          this.educationInfo.educationCycle = _result.data.educationCycle, // 교육주기
          this.educationInfo.educationKindCdLarge = _result.data.educationKindCdLarge, // 교육종류 대
          this.educationInfo.educationKindCdSmall = _result.data.educationKindCdSmall, // 교육종류 소
          this.educationInfo.educationPurpose = _result.data.educationPurpose, // 학습목적
          this.educationInfo.legalEducationFlag = _result.data.legalEducationFlag, // 법정여부
          this.educationInfo.mainTargetAudience = _result.data.mainTargetAudience, // 주요대상자
          this.educationInfo.relationLaws = _result.data.relationLaws, // 관련법규
          this.educationInfo.educationTime = _result.data.educationTime // 교육시간
          this.educationInfo.estimatedEducationExpenses = _result.data.estimatedEducationExpenses // 교육시간
          this.educationInfo.eduSubjectList = _result.data.eduCourseSubjectList;
          
          this.$_.forEach(this.educationInfo.eduSubjectList, _item => {
            _item.eduEducationId = this.param.eduEducationId
            _item.editFlag = 'C'
          })
        },);
      }
    },
    setData(data) {
      if (data) {
        this.tabItems = [
          // 기본정보
          { key: uid(), name: 'educationPlanInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./educationPlanInfo.vue'}`) },
          // 교육대상자
          { key: uid(), name: 'educationResultTargetUser', icon: 'how_to_reg', label: 'LBL0002875', component: () => import(`${'./educationResultTargetUser.vue'}`), disabled: false },
          // 교육교재
          { key: uid(), name: 'educationTextBook', icon: 'checklist', label: 'LBL0002809', component: () => import(`${'./educationTextBook.vue'}`), disabled: false },
        ]
      }
      this.getDetail();
    },
    planComplete(type) {
      let content = '';
      if (type === 'COMPLETE') content = 'MSG0000863' // 계획완료하시겠습니까?(저장과 함께 처리됩니다.)
      else content = 'MSG0000862' // 결재하시겠습니까?(저장과 함께 처리됩니다.)
      let flag = false;
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: content,
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.educationInfo.regUserId = this.$store.getters.user.userId
            this.educationInfo.chgUserId = this.$store.getters.user.userId
            this.educationInfo.documentStatusCd = 'ESC000005'
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.educationInfo;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.saveCall.saveCallData = uid();
              if (this.educationInfo.documentStatusCd === 'ESC000005') {
                this.$emit('emitStep', {
                  name: 'currentStep',
                  param: {
                    step: 'ESC0000010', // 교육결과로 이동
                    key: this.param.eduEducationId, // key
                  }
                })
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.param.eduEducationId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            
            this.$emit('emitStep', {
              name: 'closePopup',
              param: {},
            })
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getDetail();
    },
  }
};
</script>
